<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradientll" x1="0.706" x2="0.153" y1="0.051" y2="1">
     <stop offset="0" stop-color="#963596"/>
     <stop offset="1"/>
    </linearGradient>
   </defs>
   <g id="TikTok" transform="translate(-450 -208)">
    <rect :fill="background" data-name="Rectangle 21" height="60" id="Rectangle_21" transform="translate(450 208)" width="60"/>
    <path d="M16.057.025C17.695,0,19.32.012,20.945,0a7.786,7.786,0,0,0,2.187,5.212,8.815,8.815,0,0,0,5.3,2.237v5.037a13.379,13.379,0,0,1-5.25-1.212,15.444,15.444,0,0,1-2.025-1.162c-.013,3.65.012,7.3-.025,10.937a9.547,9.547,0,0,1-1.687,4.925,9.313,9.313,0,0,1-7.387,4.012,9.114,9.114,0,0,1-5.1-1.287,9.426,9.426,0,0,1-4.562-7.137c-.025-.625-.037-1.25-.013-1.862a9.41,9.41,0,0,1,10.912-8.35c.025,1.85-.05,3.7-.05,5.55A4.289,4.289,0,0,0,7.77,19.55a4.959,4.959,0,0,0-.175,2.012A4.254,4.254,0,0,0,11.97,25.15a4.2,4.2,0,0,0,3.462-2.012,2.884,2.884,0,0,0,.512-1.325c.125-2.237.075-4.462.087-6.7.012-5.037-.012-10.062.025-15.087Z" data-name="Icon simple-tiktok" id="Icon_simple-tiktok" style="fill: url(#linear-gradientll);" transform="translate(464.631 223)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>